@font-face {
  font-family: 'Creato Display';
  src: url('./fonts/CreatoDisplay-Regular.otf') format('opentype');
  font-weight: normal;
}

@font-face {
  font-family: 'Creato Display';
  src: url('./fonts/CreatoDisplay-Bold.otf') format('opentype');
  font-weight: bold;
}

@font-face {
  font-family: "Noto Sans Thai";
  font-style: normal;
  font-weight: light;
  src: url(./fonts/fontsPynk/NotoSansThai-Light.ttf);
}

@font-face {
  font-family: "Noto Sans Thai";
  src: url(./fonts/fontsPynk/NotoSansThai-Thin.ttf);
  font-weight: thin;
}

@font-face {
  font-family: "Noto Sans Thai";
  src: url(./fonts/fontsPynk/NotoSansThai-Regular.ttf);
  font-weight: normal;
}

@font-face {
  font-family: "Noto Sans Thai";
  src: url(./fonts/fontsPynk/NotoSansThai-Medium.ttf);
  font-weight: medium;
}

@font-face {
  font-family: "Noto Sans Thai";
  src: url(./fonts/fontsPynk/NotoSansThai-SemiBold.ttf);
  font-weight: semi-bold;
}

@font-face {
  font-family: "Noto Sans Thai";
  src: url(./fonts/fontsPynk/NotoSansThai-Bold.ttf);
  font-weight: bold;
}

body {
  font-family: 'Noto Sans Thai', 'Creato Display' ,sans-serif;
  font-weight: normal;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.header {
  padding: 7%;
  text-align: center;
  background: #f0eef3;
  object-fit: cover;
  background-image: url("./assets/img/bgintro2_lg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #f45197;
  display: flex;
  flex-direction: column;
}
.text-header{
  font-family: 'Creato Display';
  font-size: 82px;
  color: black;
}
.watch_introduction {
  font-size: 18px;
  cursor: pointer;
  float: left;
  margin-top: 70px;
  color: black;
}

@media (max-width: 769px) {
  .header {
    background-image: url("./assets/img/bgintro2_lg.png");
  }
  .text-header{
    font-size: 54px;
  }
}

@media (max-width: 450px) {
  .header {
    background-image: url("./assets/img/bgintro2_sm.png");
  }
  .text-header{
    font-size: 40px;
  }
  .watch_introduction {
    position: relative;
    margin-bottom: 20%;
    left: 5%;
  }
}

.popupIntroVDO {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
  background: rgba(0, 0, 0, 0.95);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
}

.popupIntroVDO.active {
  visibility: visible;
  opacity: 1;
}

.popupIntroVDO video {
  position: relative;
  max-width: 900px;
  outline: none;
}

.popupIntroVDO .close {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
  filter: invert(1);
  max-width: 32px;
}

@media (max-width: 991px) {
  .popupIntroVDO video {
    max-width: 90%;
  }
}

.overlayPopupQuestionnaire {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 2;
  display: block;
}

.overlayPopupQuestionnaire.active {
  display: none;
}

.popupQuestionnaire {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
  background: rgba(255, 255, 255, 1);
  width: 80%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  border-radius: 10px;
}

.popupQuestionnaire.active {
  visibility: visible;
  opacity: 1;
}

.popupQuestionnaire .close {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
  filter: invert(1);
  max-width: 32px;
}

@media (max-width: 991px) {
  .popupQuestionnaire video {
    max-width: 90%;
  }
}

.overlayPopupNews {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 2;
  display: block;
}

.overlayPopupNews.active {
  display: none;
}

.popupNews {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
  background: rgba(255, 255, 255, 1);
  width: 80%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  border-radius: 10px;
}

.popupNews.active {
  visibility: visible;
  opacity: 1;
}

.popupNews .close {
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
  filter: invert(1);
  max-width: 32px;
}

@media (max-width: 991px) {
  .popupNews video {
    max-width: 90%;
  }
}

.fa-bell {
  font-size: 40px;
  margin-right: 10px;
}

.bell-default {
  color: #fff;
  position: relative;
  transform-origin: top;
  cursor: pointer;
}

.bell-notification {
  color: #fff;
  position: relative;
  animation: bell 1s linear infinite alternate-reverse;
  transform-origin: top;
  cursor: pointer;
}

@keyframes bell {
  0% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(10deg);
  }
}

.bell-notification::after {
  content: attr(current-count);
  position: absolute;
  top: -10%;
  right: -0%;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border: 3px solid #01111b;
}
