.all-row-login {
  background: url("../assets/img/mainbg.jpg");
  background-size: cover; /* ปรับขนาดให้เต็มหน้าจอ */
  background-position: center; /* จัดตำแหน่งให้ตรงกลาง */
  background-repeat: no-repeat;
  padding: 10% 10%;
  width: 100%;
  height: auto;
}

.auth-card {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: white;
  padding: 25px;

  .form-side-login {
    padding: 10px;
  }
}
.container-login {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-login {
  width: 100%;
}

.underline-on-hover:hover {
  text-decoration: underline;
}
.description {
  text-align: center;
}

.text-welcome {
  margin: 0;
  font-size: 24px;
}
.text-7-day {
  font-family: 'Creato Display';
  margin: 0;
  font-size: 53px;
}
.text-pilates-ring {
  font-family: 'Creato Display';
  margin-top: -30px;
  font-size: 53px;
  font-weight: bold;
}

@media only screen and (max-width: 1026px) {
  .text-welcome {
    font-size: 18px;
  }
  .text-7-day {
    font-size: 40px;
  }
  .text-pilates-ring {
    margin-top: -24px;
    margin-bottom: 0;
    font-size: 40px;
  }
}

@media screen and (max-width: 991px) {
  .auth-card {
    flex-direction: column-reverse;
  }
  .container-login {
    text-align: center;
  }
}

@media only screen and (max-width: 576px) {
  .text-welcome {
    font-size: 14px;
  }
  .text-7-day {
    font-size: 28px;
  }
  .text-pilates-ring {
    margin-top: -18px;
    margin-bottom: 0;
    font-size: 28px;
  }
}
